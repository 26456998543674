const IMAGES = {
  // LOGO
  logo: require("../img/logo.png"),
  giveaway: require("../img/home/giveaway.png"),
  //HOME
  banner1: require("../img/home/banner1.jpeg"),
  banner2: require("../img/home/banner2.jpeg"),
  banner3: require("../img/home/banner3.jpeg"),
  banner4: require("../img/home/banner4.jpeg"),
  banner5: require("../img/home/banner5.jpeg"),
  safe: require("../img/products/safe.png"),

  m1: require("../img/about/m1.png"),
  m2: require("../img/about/m2.png"),
  m3: require("../img/about/m4.webp"),
  m4: require("../img/about/m4.png"),
  /// PHONE SKIN
  pbanner: require("../img/products/banner2.webp"),
  // barcode
  barcode: require("../img/bar.jpeg"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
  maint: require("./2.jpg"),
};

export default IMAGES;
